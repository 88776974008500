import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import {
  ListCuentaConcentradora,
  ListGiro,
  ListReferenciado
} from "../../../services/interfaces/clientes/clientes";

@Injectable({
  providedIn: "root"
})
export class ClientesService {
  private serverUrl: String;
  headers: any;

  constructor(private http: HttpClient) {
    this.serverUrl = environment.urlServe + "clientes";
  }

  getClients(id, oficina, esConcentradora, oficinasRel) {
    let params = new HttpParams().set("id", id);
    params = params.append("oficina", oficina);
    params = params.append("esConcentradora", esConcentradora);
    params = params.append("oficinasRel", oficinasRel);
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getClients.php`;
    return this.http.post(path, params, this.headers);
  }

  getDetailClient(idPersonal, idCliente) {
    let params = new HttpParams().set("idPersonal", idPersonal);
    params = params.append("idCliente", idCliente);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getDetailClient.php`;
    return this.http.post(path, params, this.headers);
  }

  getListGiro() {
    const path = `${this.serverUrl}/options/getListGiro.php`;
    return this.http.get<ListGiro[]>(path);
  }

  getListOficina(idOficina, esConcentradora, oficinasRel) {
    let params = new HttpParams().set("idOficina", idOficina);
    params = params.append("esConcentradora", esConcentradora);
    params = params.append("oficinasRel", oficinasRel);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/options/getListOficina.php`;
    return this.http.post(path, params, this.headers);
  }

  getListSocios(idOficina, oficinasRel, esConcentradora, oficinaConcentradora) {
    let params = new HttpParams().set("idOficina", idOficina);
    params = params.append("esConcentradora", esConcentradora);
    params = params.append("oficinasRel", oficinasRel);
    params = params.append("oficinaConcentradora", oficinaConcentradora);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/options/getListSociosOficina.php`;
    return this.http.post(path, params, this.headers);
  }

  getListReferencia() {
    const path = `${this.serverUrl}/options/getListReferencias.php`;
    return this.http.get<ListReferenciado[]>(path);
  }

  getListConcentradora() {
    const path = `${this.serverUrl}/options/getListConcentradora.php`;
    return this.http.get<ListCuentaConcentradora[]>(path);
  }

  getResAddClient(data, email) {
    let params = new HttpParams().set("dataForm", JSON.stringify(data));
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/addClient.php`;
    return this.http.post(path, params, this.headers);
  }

  getEditClientData(id) {
    let params = new HttpParams().set("id", id);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/editClient.php`;
    return this.http.post(path, params, this.headers);
  }

  getResUpdateCliente(data, id, step, email) {
    let params = new HttpParams().set("dataForm", JSON.stringify(data));
    params = params.append("id", id);
    params = params.append("step", step);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/updateClient.php`;
    return this.http.post(path, params, this.headers);
  }

  checkProjectsActive(id) {
    let params = new HttpParams().set("id", id);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/checkProjects.php`;
    return this.http.post(path, params, this.headers);
  }

  getResDeleteClient(id, email) {
    let params = new HttpParams().set("id", id);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/deleteClient.php`;
    return this.http.post(path, params, this.headers);
  }

  getDisabledClients(id, oficina, esConcentradora, oficinasRel) {
    let params = new HttpParams().set("id", id);
    params = params.append("oficina", oficina);
    params = params.append("esConcentradora", esConcentradora);
    params = params.append("oficinasRel", oficinasRel);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/disabledClients.php`;
    return this.http.post(path, params, this.headers);
  }

  getResReactiveClient(id, email) {
    let params = new HttpParams().set("id", id);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/reactiveClient.php`;
    return this.http.post(path, params, this.headers);
  }

  getProjectsClient(
    idPersonal,
    oficina,
    esConcentradora,
    oficinasRel,
    idCliente
  ) {
    let params = new HttpParams().set("id", idPersonal);
    params = params.append("oficina", oficina);
    params = params.append("esConcentradora", esConcentradora);
    params = params.append("oficinasRel", oficinasRel);
    params = params.append("idCliente", idCliente);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getProjectsClient.php`;
    return this.http.post(path, params, this.headers);
  }

  getClientContacts(idClient) {
    let params = new HttpParams().set("idClient", idClient);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getClientContacts.php`;
    return this.http.post(path, params, this.headers);
  }

  getResClientContactAdd(id, name, lastName, email, job, phone, notes, comunicado, esContactoPrincipal, emailBit) {
    let params = new HttpParams().set("id", id);
    params = params.append("name", name);
    params = params.append("lastName", lastName);
    params = params.append("email", email);
    params = params.append("job", job);
    params = params.append("phone", phone);
    params = params.append("notes", notes); // CAMBIO 26-OCT-2022
    params = params.append("comunicado", comunicado); // CAMBIO 26-OCT-2022
    params = params.append("esContactoPrincipal", esContactoPrincipal); // CAMBIO 26-OCT-2022
    params = params.append("emailBitacora", emailBit);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getResContactClient.php`;
    return this.http.post(path, params, this.headers);
  }

  updateContact(idCliente, form, user) {
    let params = new HttpParams().set("idCliente", idCliente);
    params = params.append("form", JSON.stringify(form));
    params = params.append("user", user);
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/updateContact.php`;
    return this.http.post(path, params, this.headers);
  }

  // CAMBIO 25-OCT-2022

  getListRelacionFirma() {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const path = `${this.serverUrl}/options/getListRelacionFirma.php`;
    return this.http.post(path, new HttpParams(), this.headers);
  }

  getListOrigenCliente() {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const path = `${this.serverUrl}/options/getListOrigenCliente.php`;
    return this.http.post(path, new HttpParams(), this.headers);
  }
}
