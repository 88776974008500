import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class ReservacionService {
  private serverUrl: String;
  headers: any;

  constructor(private http: HttpClient) {
    this.serverUrl = environment.urlServe + "reservacion";
  }

  getResAddReservation(
    idEmployee,
    startTime,
    endTime,
    note,
    mainName,
    dayRegistration,
    email,
    emailBitacora
  ) {
    let params = new HttpParams().set("idEmployee", idEmployee);
    params = params.append("startTime", startTime);
    params = params.append("endTime", endTime);
    params = params.append("note", note);
    params = params.append("mainName", mainName);
    params = params.append("dayRegistration", dayRegistration);
    params = params.append("email", email);
    params = params.append("emailBitacora", emailBitacora);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/addReservation.php`;
    return this.http.post(path, params, this.headers);
  }

  getDetialReservations(
    idEmployee,
    zonaHoraria,
    dayRegistration,
    idOffice,
    availablePlaces
  ) {
    let params = new HttpParams().set("idEmployee", idEmployee);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("idOffice", idOffice);
    params = params.append("dayRegistration", dayRegistration);
    params = params.append("availablePlaces", availablePlaces);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getDetailReservations.php`;
    return this.http.post(path, params, this.headers);
  }

  getDetialReservationsDay(zonaHoraria, dayRegistration, availablePlaces) {
    let params = new HttpParams().set("zonaHoraria", zonaHoraria);
    params = params.append("dayRegistration", dayRegistration);
    params = params.append("availablePlaces", availablePlaces);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getDetailReservationsDay.php`;
    return this.http.post(path, params, this.headers);
  }

  getResDeleteReservation(id, email) {
    let params = new HttpParams().set("id", id);
    params = params.append("email", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/deleteReservation.php`;
    return this.http.post(path, params, this.headers);
  }

  //Marco
  getReservations(initDate, oficina, idUser, email) {
    let params = new HttpParams().set("initDate", initDate);
    params = params.append("oficina", oficina);
    params = params.append("idPersonal", idUser);
    params = params.append("emailBitacora", email);
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const path = `${this.serverUrl}/getReservations.php`;
    let res = this.http.post(path, params, this.headers);
    return res;
  }

  getSalones(idOficina) {
    let params = new HttpParams().set("idOficina", idOficina);
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const path = `${this.serverUrl}/reservar-lugares/getSalones.php`;

    return this.http.post(path, params, this.headers);
  }

  getAsientos(idSalon, fechaInicio, fechaTermino, idUsuario) {
    let params = new HttpParams().set("idSalon", idSalon);
    params = params.append("fechaInicio", fechaInicio);
    params = params.append("fechaTermino", fechaTermino);
    params = params.append("idUsuario", idUsuario);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const path = `${this.serverUrl}/reservar-lugares/getLugares.php`;

    return this.http.post(path, params, this.headers);
  }

  addReservacion(
    idPersonal,
    idLugar,
    fechaInicio,
    fechaTermino,
    notasRegistro,
    estatusRegistro,
    email
  ) {
    let params = new HttpParams().set("idPersonal", idPersonal);
    params = params.append("idLugar", idLugar);
    params = params.append("fechaInicio", fechaInicio);
    params = params.append("fechaTermino", fechaTermino);
    params = params.append("notasRegistro", notasRegistro);
    params = params.append("estatusRegistro", estatusRegistro);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    const path = `${this.serverUrl}/reservar-lugares/addReservaciones.php`;

    return this.http.post(path, params, this.headers);
  }
}
