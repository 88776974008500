import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Gasto } from "../../interfaces/gastos/gastos";

@Injectable({
  providedIn: "root"
})
export class GastosService {
  private serverUrl: String;
  headers: any;

  constructor(private http: HttpClient) {
    this.serverUrl = environment.urlServe + "gastos";
  }

  addGasto(gasto: Gasto, email: string) {
    let fd = new FormData();

    fd.append("idPersonal", gasto.idPersonal);
    fd.append("idTipoGasto", gasto.idTipoGasto);
    fd.append("idProyecto", gasto.idProyecto);
    fd.append("fechaGasto", gasto.fechaGasto);
    fd.append("monto", gasto.monto);
    fd.append("imagen", gasto.imagen, gasto.imagen.name);
    fd.append("notas", gasto.notas);
    fd.append("estatus", gasto.estatus);
    fd.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/addGasto.php`;
    return this.http.post(path, fd, this.headers);
  }

  updateGasto(gasto: Gasto, email: string) {
    let fd = new FormData();

    fd.append("idGasto", gasto.idGasto);
    fd.append("idPersonal", gasto.idPersonal);
    fd.append("idProyecto", gasto.idProyecto);
    fd.append("idTipoGasto", gasto.idTipoGasto);
    fd.append("fechaGasto", gasto.fechaGasto);
    fd.append("monto", gasto.monto);
    fd.append("imagen", gasto.imagen);
    fd.append("notas", gasto.notas);
    fd.append("estatus", gasto.estatus);
    fd.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/updateGasto.php`;
    return this.http.post(path, fd, this.headers);
  }

  getGastos(
    idPersonal,
    tipoPersonal,
    oficina,
    oficinaConcentradora,
    socioDirectorOficina,
    esConcentradora
  ) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    let params = new HttpParams();
    params = params.append("idPersonal", idPersonal);
    params = params.append("tipoPersonal", tipoPersonal);
    params = params.append("oficina", oficina);
    params = params.append("oficinaConcentradora", oficinaConcentradora);
    params = params.append("directorOficina", socioDirectorOficina);
    params = params.append("esConcentradora", esConcentradora);

    const path = `${this.serverUrl}/getAllGastos.php`;
    return this.http.post(path, params, this.headers);
  }

  getGastosById(idPersonal: string, fechaGasto: string) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    let params = new HttpParams();
    params = params.append("idPersonal", idPersonal);
    params = params.append("fechaGasto", fechaGasto);

    const path = `${this.serverUrl}/getGastosById.php`;
    return this.http.post(path, params, this.headers);
  }

  getTipos() {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    let params = new HttpParams();
    const path = `${this.serverUrl}/getTipos.php`;
    return this.http.post(path, params, this.headers);
  }

  getProyectos(idPersonal: string, fechaGasto: string) {
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");
    let params = new HttpParams();
    params = params.append("id", idPersonal);
    params = params.append("diaRegistroHoras", fechaGasto);
    const path = `${environment.urlServe}/control-tiempos/registro-horas/getProjects.php`;
    return this.http.post(path, params, this.headers);
  }
}
