import * as XLSX from 'xlsx-js-style';

const getFileName = (name: string) => {
  let timeSpan = new Date().toISOString();
  let sheetName = name || "Reportes";
  let fileName = `${sheetName}-${timeSpan}`;
  return {
    sheetName,
    fileName
  };
};

export class ExportExcel {

  static exportToExcel(arr: any[], name?: string, header?: any[], params?: any[]) {
    let { sheetName, fileName } = getFileName(name);

    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();

    let totalCells = 5;

    XLSX.utils.sheet_add_aoa(ws, header);
    XLSX.utils.sheet_add_aoa(ws, [params.map(p => p.title)], {origin: 'A' + totalCells});

    totalCells++;
    for(let i = 0; i < arr.length; i++) {
      XLSX.utils.sheet_add_aoa(ws, [params.map(p => arr[i][p.param])], {origin: 'A' + totalCells});
      totalCells++;
    }

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
}