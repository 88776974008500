import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

import { ListIncidence } from "src/app/services/interfaces/control-tiempos/registro-horas";

@Injectable({
  providedIn: "root"
})
export class RegistroHorasService {
  private serverUrl: String;
  headers: any;

  constructor(private http: HttpClient) {
    this.serverUrl = environment.urlServe + "control-tiempos/registro-horas";
  }

  getHourDetails(id, date, zonaHoraria) {
    let params = new HttpParams().set("id", id);
    params = params.append("date", date);
    params = params.append("zonaHoraria", zonaHoraria);
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getHourDetails.php`;
    return this.http.post(path, params, this.headers);
  }

  getDataPerMonth(id, year, month, zonaHoraria, workingDay) {
    let params = new HttpParams().set("id", id);
    params = params.append("year", year);
    params = params.append("month", month);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("workingDay", workingDay);
    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getDataPerMonth.php`;
    return this.http.post(path, params, this.headers);
  }

  getResDeleteTimerRecord(id, email) {
    let params = new HttpParams().set("id", id);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/deleteRecordedHours.php`;
    return this.http.post(path, params, this.headers);
  }

  getDataProjectsByUser(id, diaRegistroHoras) {
    let params = new HttpParams().set("id", id);
    params = params.append("diaRegistroHoras", diaRegistroHoras);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getProjects.php`;
    return this.http.post(path, params, this.headers);
  }

  getDataActivitiesByUser(id) {
    let params = new HttpParams().set("id", id);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/getActivities.php`;
    return this.http.post(path, params, this.headers);
  }

  addRecordHour(
    idPersonal,
    dataForm,
    costoHora,
    precioHora,
    date,
    zonaHoraria,
    email
  ) {
    let params = new HttpParams().set("id", idPersonal);
    params = params.append("data", JSON.stringify(dataForm));
    params = params.append("costoHora", costoHora);
    params = params.append("precioHora", precioHora);
    params = params.append("date", date);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/addRecordHour.php`;
    return this.http.post(path, params, this.headers);
  }

  getDataEditRecord(id) {
    let params = new HttpParams().set("id", id);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/loadEditRecord.php`;
    return this.http.post(path, params, this.headers);
  }

  updateRecordHour(id, hour, note, date, zonaHoraria, email) {
    let params = new HttpParams().set("idRegistro", id);
    params = params.append("hour", hour);
    params = params.append("note", note);
    params = params.append("date", date);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/editRecordHour.php`;
    return this.http.post(path, params, this.headers);
  }

  stopCounterRes(id, zonaHoraria, email) {
    let params = new HttpParams().set("id", id);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/stopCounter.php`;
    return this.http.post(path, params, this.headers);
  }

  startCounterRes(
    idRegistro,
    idProyecto,
    idActividad,
    idPersonal,
    date,
    zonaHoraria,
    email
  ) {
    let params = new HttpParams().set("idRegistro", idRegistro);
    params = params.append("idProyecto", idProyecto);
    params = params.append("idActividad", idActividad);
    params = params.append("idPersonal", idPersonal);
    params = params.append("date", date);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/startCounter.php`;
    return this.http.post(path, params, this.headers);
  }

  getListIncidences() {
    const path = `${this.serverUrl}/options/getListIncidences.php`;
    return this.http.get<ListIncidence[]>(path);
  }

  getResAddIncidence(data, idEmployee, zonaHoraria, workedSaturday, email) {
    let params = new HttpParams().set("data", JSON.stringify(data));
    params = params.append("idEmployee", idEmployee);
    params = params.append("zonaHoraria", zonaHoraria);
    params = params.append("workedSaturday", workedSaturday);
    params = params.append("emailBitacora", email);

    this.headers = new Headers();
    this.headers.append("Content-Type", "application/json");

    const path = `${this.serverUrl}/addIncidence.php`;
    return this.http.post(path, params, this.headers);
  }
}
