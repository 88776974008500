import * as XLSX from 'xlsx';
import * as moment from 'moment';
import { ExportContacts } from '../services/interfaces/configuracion/configuracion';

const getFileName = (name: string) => {
    let timeSpan = new Date().toISOString();
    let sheetName = name || "HorasRegistradas";
    let fileName = `${sheetName}-${timeSpan}`;
    return {
      sheetName,
      fileName
    };
  };

const setName = (name: string) => {
  if(name.length > 31) {
      let aux = "";
      for(let i = 0; i < 31; i++) {
        aux += name[i];
      }
      return aux;
  }

  return name;
}

export class ExportExcel {

  static exportArrayToExcel(arr: any[], name?: string, header?: any[] ) {
    let { sheetName, fileName } = getFileName(name);

    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();
    
    XLSX.utils.sheet_add_aoa(ws, header);

    //Starting in the second row to avoid overriding and skipping headers
    XLSX.utils.sheet_add_json(ws, arr, { origin: 'A2' });
    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static exportExcel(arr: any[], params: any[], name: string, header: string) {
    let { sheetName, fileName } = getFileName(name);

    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();
    
    XLSX.utils.sheet_add_aoa(ws, [[header + moment().format("LL")]]);

    XLSX.utils.sheet_add_json(ws, arr, { origin: 'A3' });
    XLSX.utils.sheet_add_aoa(ws, [params.map(p => p)], {origin: 'A3'});
    XLSX.utils.book_append_sheet(wb, ws, setName(sheetName));

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }
    
  static exportToExcel(arr: any[], name?: string, header?: any[], params?: any[]) {
    let { sheetName, fileName } = getFileName(name);

    var wb = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();

    let totalCells = 5;

    XLSX.utils.sheet_add_aoa(ws, header);
    XLSX.utils.sheet_add_aoa(ws, [params.map(p => p.title)], {origin: 'A' + totalCells});

    totalCells++;
    for(let i = 0; i < arr.length; i++) {
      XLSX.utils.sheet_add_aoa(ws, [params.map(p => arr[i][p.param])], {origin: 'A' + totalCells});
      totalCells++;
    }

    XLSX.utils.book_append_sheet(wb, ws, sheetName);

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }


  static exportZoom(arr: any[], name) {
    let { sheetName, fileName } = getFileName(name);
    let totalCells = 1
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.book_new();
    
    for(let i = 0; i < arr.length; i++) {
      if(arr[i].invitado) {
        XLSX.utils.sheet_add_aoa(ws, [ [ arr[i].email, arr[i].firstname, arr[i].lastname, arr[i].oficina, arr[i].area, arr[i].puesto, arr[i].tipoAsistencia ] ], {origin: 'A' + totalCells});
        totalCells++;
      }
    }

    XLSX.utils.book_append_sheet(wb, ws, setName(sheetName));
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static exportCapacitaciones(name, date, header: any[], data: any[]) {
    let { sheetName, fileName } = getFileName(name);

    const wb = XLSX.utils.book_new();
    let ws = XLSX.utils.book_new();
    
    const capacitaciones = [[" "].concat(header.map(x => x.dpc === 1 ? x.nombreCapacitacion + " *" : x.nombreCapacitacion ))]
    const tiempos = [["Personal"].concat(header.map(x => x.duracion)).concat(["Plan", "Participación", "DPC", "Total"])]
    
    let totalCells = 6;

    for(let i = 0; i < data.length; i++) {
      if(!data[i].nombreOficina) {
        const item = data[i];
        const arr = [ [ item.personal ].concat(item.capacitaciones.map((x: any) => x.participacion)).concat([item.plan, item.tiempoParticipacion, item.tiempoDPC, item.tiempoTotal]) ]
        XLSX.utils.sheet_add_aoa(ws, arr, {origin: 'A'+totalCells});
        totalCells++;
      } else {
        totalCells = 6;
        ws = XLSX.utils.book_new();
        

        XLSX.utils.sheet_add_aoa(ws, [['Capacitaciones ' + date]]);
        XLSX.utils.sheet_add_aoa(ws, capacitaciones, {origin: 'A3'});
        XLSX.utils.sheet_add_aoa(ws, tiempos, {origin: 'A4'});
        XLSX.utils.book_append_sheet(wb, ws, setName(data[i].nombreOficina));
      }
    }

    //XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

  static exportContactsToExcel(arr: ExportContacts[], name) {
    let { fileName } = getFileName(name);
    const wb = XLSX.utils.book_new();
    let ws = null;
    for(let i = 0; i < arr.length; i++) {
      ws = XLSX.utils.book_new();
      XLSX.utils.sheet_add_json(ws, arr[i].Contactos, { origin: 'A1' });
      XLSX.utils.book_append_sheet(wb, ws, setName(arr[i].Oficina));
    }

    XLSX.writeFile(wb, `${fileName}.xlsx`);
  }

}

